.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2 {
  font-size: calc(2em + 2vmin);
  font-weight: 900;
}

blockquote {
  max-width: 500px;
  text-align: center;
  margin: 3em auto;
}

img {
  margin: 3em;
  transform: scale(1.2)
}